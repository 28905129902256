import * as React from 'react';

import { connect } from 'react-redux';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import Logger from '@sympli/ui-logger';
import _merge from 'lodash-es/merge';
import _uniqueId from 'lodash-es/uniqueId';
import queryString from 'query-string';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { pushGlobalError } from 'src/actions/globalErrors';
import { MainAppBar } from 'src/components/layout';
import GetStart from 'src/containers/onboarding/views/get-start';
import { ApplicationErrorModel, ApplicationStatusEnum } from 'src/models';
import { ConnectedDispatchProps } from 'src/models/redux';
import { Store } from 'src/reducers';
import { ErrorType } from 'src/reducers/globalErrors';
import Api from 'src/utils/http';

import { onboardingDefaultValues, OnboardingFullModel } from './models';
import styles, { ClassKeys } from './styles';
import IntroPage from './views/intro';
import OnboardingDetailPage from './views/onboarding-detail';
import { OnboardingDetailsModel, OnboardingFormStepsEnum } from './views/onboarding-detail/models';
import RegistrationInProgress from './views/registration-in-progress';
import VerificationLayer from './views/verification';

// * A component act as a router
// const IS_DEV_ENV = import.meta.env.DEV;
interface OwnProps {}

type ConnectedStateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & ConnectedStateProps & ConnectedDispatchProps & WithStyles<ClassKeys>;

interface State {
  verifyCodeError?: string;
  showLoader: boolean;
  applicationStatus: ApplicationStatusEnum;
}

class OnboardingPage extends React.PureComponent<Props, State> {
  private onboardValues: OnboardingFullModel;
  private initialStep: OnboardingFormStepsEnum;

  constructor(props: Props) {
    super(props);

    this.onboardValues = this.initializeValue();
    this.initialStep = this.onboardValues.details.step || 0;
    this.state = {
      showLoader: false,
      verifyCodeError: '',
      applicationStatus: this.onboardValues.applicationStatus
    };
  }

  private initializeValue(): OnboardingFullModel {
    const defaultValues: OnboardingFullModel = onboardingDefaultValues;
    return defaultValues;
  }

  public componentDidMount() {
    const urlToken = queryString.parse(window.location.search).urlToken as string;
    if (!!urlToken) {
      // * Intialize step to organization detail
      this.setState({ showLoader: true });
      this.loadData();
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    window.scrollTo(0, 0);
  }

  render() {
    const { showLoader } = this.state;
    const { classes } = this.props;
    const { dispatch } = this.props;

    return (
      <React.Fragment>
        <MainAppBar />
        <div className={classes.body}>
          {this.props.showVerification || this.props.showVerificationPopup ? (
            <VerificationLayer
              onVerify={this.handleOnVerify}
              errorMessage={this.state.verifyCodeError}
              showVerificationPopup={this.props.showVerificationPopup}
              dispatch={dispatch}
            />
          ) : (
            <>{showLoader ? <BlockLoader /> : this.renderStatus()}</>
          )}
        </div>
      </React.Fragment>
    );
  }

  private renderStatus() {
    const { dispatch } = this.props;
    switch (this.state.applicationStatus) {
      case ApplicationStatusEnum.Intro: {
        return <IntroPage onGetStarted={this.mergeValues} initialValues={this.onboardValues.details.intro} dispatch={dispatch} />;
      }
      case ApplicationStatusEnum.Pending: {
        return <GetStart applicant={this.onboardValues.details.intro.registeredPerson} />;
      }
      case ApplicationStatusEnum.Editing: {
        return (
          <OnboardingDetailPage
            dispatch={dispatch}
            applicationErrors={this.onboardValues.applicationErrors}
            detailValues={this.onboardValues.details}
            initialStep={this.initialStep}
            onSubmitDetails={this.mergeValues}
            features={this.onboardValues.features}
          />
        );
      }
      case ApplicationStatusEnum.Received:
      case ApplicationStatusEnum.Reviewing:
      case ApplicationStatusEnum.Approved:
      case ApplicationStatusEnum.Rejected: {
        return <RegistrationInProgress onEditClick={this.handleOnJumpToDetailStepClick} onboardValues={this.onboardValues} />;
      }
      default:
        return 'Application status error';
    }
  }

  private loadData() {
    Api.loadData()
      .then(data => {
        _merge(this.onboardValues, data);
        this.initialStep = this.onboardValues.details.step;
        this.setState({ applicationStatus: this.onboardValues.applicationStatus, showLoader: false });
      })
      .catch(err => {
        if (err.response.status !== 401) {
          Logger.captureException(err);
          const globalError = {
            id: _uniqueId('error'),
            title: 'Fail to load previous data',
            message: err.message,
            type: 'error' as ErrorType
          };
          this.props.dispatch(pushGlobalError(globalError));
        }
        this.setState({ applicationStatus: ApplicationStatusEnum.Intro, showLoader: false });
      });
  }

  private handleOnVerify = smsCode => {
    this.setState({ verifyCodeError: undefined, showLoader: true });
    const urlToken = queryString.parse(window.location.search).urlToken as string;
    Api.getAccessToken(urlToken, smsCode)
      .then(() => {
        const url = new URL(window.location.href);
        if (url.searchParams.has('urlToken')) {
          url.searchParams.delete('urlToken');
          window.history.replaceState(null, '', url.toString());
        }
        this.loadData();
      })
      .catch(err => {
        Logger.captureException(err);
        let verifyCodeError = 'There was a problem with verifying your code. Please try again.';
        if (err.response.status === 401) {
          verifyCodeError = 'Incorrect verification code.';
        }
        this.setState({ verifyCodeError });
      });
  };

  private mergeValues = (values: {
    details?: OnboardingDetailsModel;
    applicationError?: Array<ApplicationErrorModel>;
    applicationStatus?: ApplicationStatusEnum;
  }) => {
    _merge(this.onboardValues, values);
    if (!!values.applicationStatus) {
      this.setState({ applicationStatus: values.applicationStatus });
    }
  };

  private handleOnJumpToDetailStepClick = (e: React.MouseEvent<HTMLButtonElement>, stepValue: number) => {
    this.initialStep = stepValue;
    this.setState({ applicationStatus: ApplicationStatusEnum.Editing });
  };
}

const mapStateToProps = (state: Store) => ({
  showVerification: state.application.showVerification,
  showVerificationPopup: state.application.showVerificationPopup
});

const StyledComponent = withStyles(styles)(OnboardingPage);
export default connect<ConnectedStateProps, ConnectedDispatchProps, OwnProps>(mapStateToProps)(StyledComponent);
