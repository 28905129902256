import * as React from 'react';

import { Action, Dispatch } from 'redux';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import Input from '@sympli/ui-framework/components/form/base-components/input';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import Logger from '@sympli/ui-logger';
import _uniqueId from 'lodash-es/uniqueId';
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { pushGlobalError } from 'src/actions/globalErrors';
import SvgHouse from 'src/components/svg/house';
import { ErrorMessageRequestModel } from 'src/reducers/globalErrors';
import Api from 'src/utils/http';

import styles, { ClassKeys } from './styles';

interface VerificationLayerProps {
  errorMessage?: string;
  showVerificationPopup: boolean;
  onVerify: (smsCode: string) => void;
  dispatch: Dispatch<Action>;
}

interface State {
  smsCode: string;
  errorMessage?: string;
  resendCodeSuccess: boolean;
}

type Props = VerificationLayerProps & WithStyles<ClassKeys>;

class VerificationLayer extends React.PureComponent<Props, State> {
  private verifyButton: JSX.Element;
  constructor(props: Props) {
    super(props);

    this.state = {
      smsCode: '',
      errorMessage: props.errorMessage,
      resendCodeSuccess: false
    };
    this.verifyButton = (
      <SympliButton className={props.classes.endAdornment} color="primary" variant="contained" onClick={this.handleOnVerify} arrowRight={true}>
        Verify
      </SympliButton>
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.errorMessage !== prevState.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  render() {
    return this.props.showVerificationPopup ? this.renderSessionExpiry() : this.renderPage();
  }

  private renderPage = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h1" className={classes.header}>
          Welcome to your Sympli application
        </Typography>
        <SvgHouse className={classes.img} />
        <FlexLayout justifyContent="center" alignItems="center">
          Request for new mobile verification code{' '}
          <ButtonLink className={classes.link} onClick={this.handleOnResendCode} color="inherit">
            Send Code
          </ButtonLink>
        </FlexLayout>
        {this.state.resendCodeSuccess && <div className={classes.resendSuccess}>New verification code has been sent to your phone.</div>}
        <br />
        <FlexLayout justifyContent="center">
          <Input
            value={this.state.smsCode}
            onChange={this.handleOnChange}
            error={this.state.errorMessage}
            endAdornment={this.verifyButton}
            classes={{ inputAdornmentEnd: classes.verifyInputAdornmentEnd }}
          />
        </FlexLayout>
      </div>
    );
  };

  private renderSessionExpiry = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h1" className={classes.header}>
          Your session has expired.
        </Typography>
        <SvgHouse className={classes.img} />
        <br />
        <FlexLayout justifyContent="center" alignItems="center">
          Please use the link in your verification email to access your application
        </FlexLayout>
      </div>
    );
  };

  private handleOnChange = e => {
    const smsCode = e.target.value;
    this.setState({ smsCode, errorMessage: undefined });
  };

  private validate() {
    if (this.state.smsCode.length < 5) {
      this.setState({ errorMessage: 'Please provide valid code.' });
      return false;
    }
    return true;
  }

  private handleOnVerify = () => {
    if (this.validate()) {
      this.props.onVerify(this.state.smsCode);
    }
  };

  private handleOnResendCode = () => {
    const urlToken = queryString.parse(window.window.location.search).urlToken as string;

    this.setState({ smsCode: '', errorMessage: undefined, resendCodeSuccess: false }, () => {
      Api.resendSmsCode(urlToken)
        .then(() => {
          this.setState({ resendCodeSuccess: true });
        })
        .catch(err => {
          Logger.captureException(err);
          // General network error or something
          let globalError: ErrorMessageRequestModel = {
            id: _uniqueId('error'),
            type: 'warning'
          };

          if (err && err.response && err.response.status === 403) {
            globalError = {
              ...globalError,
              title: 'Could not send code',
              message: 'Verification code could not be sent at this time (limit exceeded). Please try again soon.'
            };
          } else {
            globalError = {
              ...globalError,
              title: 'Failed to save data to the server',
              message: err.message + ' Please try again.'
            };
          }

          this.props.dispatch(pushGlobalError(globalError));
        });
    });
  };

  private handleOnClose = () => {};
}
export default withStyles(styles)(VerificationLayer);
